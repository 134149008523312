.brandoverview {
  width: 80%;
  margin: 30px auto 20px;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > img {
      object-fit: contain;
      // width: 140px;
      height: 80px;
    }
    p {
      flex: 1;
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      line-height: 36px;
      font-family: 'Montserrat';
      padding: 0px;
      margin: 0px;
    }
  }

  &-description {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    margin-top: 10px;
    // height: 300px;
    gap: 30px;
    &-gallery {
      flex: 0.5;
      width: 50%;
      //   background-color: red;
    }
    &-introduction {
      display: flex;
      flex-direction: column;
      flex: 0.55;
      width: 55%;
      color: #4d4d4d !important;
      gap: 10px;

      .brandoverview-location {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      .brandoverview-social-sites {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        a {
          padding: 8px;
          background-color: #e6e6e6;
          border-radius: 4px;
          display: flex;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      //   background: blue;
      & > p {
        text-align: left;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        margin-block-end: 0em;
        margin-block-start: 0em;
      }
      span {
        text-align: left;
        // width: 100%;
        display: inline-block;
        padding: 2px;
      }
      a {
        color: #00a0b0;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        font-family: 'Montserrat';
      }
      .desktop-description {
        display: block;
      }
    }
    .mobile-description {
      display: none;
    }
  }
  .brandoverview-rebates-ad {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    // height: 300px;
    gap: 30px;

    .brandoverview-rebates {
      flex: 0.5;
      width: 50%;
      //   background-color: red;
      p {
        text-align: left;
      }
      .rebate-item {
        padding: 5px;
        background: white;
        display: flex;
        align-items: center;
        flex-direction: row;
        img {
          object-fit: contain;
          width: 100px;
          height: 100px;
          margin-left: 10px;
        }
        a {
          color: #00a0b0;
          line-height: 24px;
          font-weight: 500;
          font-family: 'Montserrat';
        }
      }
    }
    .brandoverview-ads {
      flex: 0.5;
      width: 50%;
      color: #4d4d4d !important;

      //   background: blue;

      p {
        text-align: left;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
      span {
        text-align: left;
        width: 100%;
        display: inline-block;
      }
      a {
        color: #00a0b0;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        font-family: 'Montserrat';
      }
    }
  }
  .brandoverview-heading {
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat';
    color: #4d4d4d;
    text-align: left;
  }
}

.image-gallery-slide-wrapper {
  background: white !important;
  box-shadow: 4px 4px 10px rgba(196, 196, 196, 0.25);
  border-radius: 6px;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  height: 50px;
  color: #00a0b0;
}

.image-gallery-icon {
  filter: drop-shadow(0px 0px 0px);
}

.image-gallery-bullets .image-gallery-bullet {
  background: #dcefec;
  box-shadow: none;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #00a0b0;
}

.image-gallery-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  border-radius: 5px;
}
.image-gallery-image {
  width: 100% !important;
  object-fit: fill !important;
  object-position: top;
  height: 100%;
  max-height: 100% !important;
}

.image-gallery-thumbnails {
  margin-top: 20px;
}

.image-gallery-thumbnail {
  height: 100px;
  border: 1px solid #bababa;
  padding: 4px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 10px rgba(196, 196, 196, 0.25);
  aspect-ratio: 0.5;
  filter: brightness(0.5);
  &:hover {
    border: 1px solid #bababa;
  }
  & + & {
    margin-left: 15px;
  }
}

.image-gallery-thumbnail.active {
  border: 1px solid #bababa;
  background: white;
  padding: 4px;
  border-radius: 6px;
  filter: brightness(1);
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 90%;
}

.image-gallery-thumbnail-image {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .image-gallery-slide-wrapper {
    background: white !important;
  }

  .image-gallery-slide {
    height: 100%;
  }

  .image-gallery-image {
    height: 330px;
  }

  .image-gallery-thumbnails {
    margin-top: 20px;
    display: none;
  }

  .image-gallery-thumbnail {
    height: 80px;
    cursor: pointer;
    &:hover {
      border: 1px solid #bababa;
    }
    & + & {
      margin-left: 15px;
    }
  }

  .image-gallery-thumbnail.active {
    border: 1px solid #bababa;
    background: white;
    padding: 4px;
  }
}

@media screen and (max-width: 512px) {
  .image-gallery-slide-wrapper {
    background: white !important;
  }

  .image-gallery-slide {
    height: 100%;
  }

  .image-gallery-image {
    height: 250px;
  }

  .image-gallery-thumbnails {
    margin-top: 20px;
    display: none;
  }

  .image-gallery-thumbnail {
    height: 80px;
    cursor: pointer;
    &:hover {
      border: 1px solid #bababa;
    }
    & + & {
      margin-left: 15px;
    }
  }

  .image-gallery-thumbnail.active {
    border: 1px solid #bababa;
    background: white;
    padding: 4px;
  }
}

@media screen and (max-width: 768px) {
  .brandoverview {
    width: 95%;
    margin-top: 20px;

    &-header {
      flex-direction: column;
      gap: 0px;
      > img {
        object-fit: contain;
        // width: 80px;
        height: 60px;
      }
    }
    &-social-sites {
      flex-wrap: wrap;
      gap: 8px;
      a {
        padding: 6px;
        border-radius: 2px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .brandoverview-description {
      flex-direction: column;
      gap: 10px;
      &-gallery {
        flex: 1;
        width: 100%;
      }
      &-introduction {
        flex: 1;
        width: 100%;
        text-align: center;
        flex-direction: column-reverse;
        .desktop-description {
          display: none;
        }
      }
      & > p {
        text-align: left;
        margin: 0px;
        padding: 0px;
      }
      .mobile-description {
        display: block;
      }
    }
    .brandoverview-rebates-ad {
      padding: 5px 0px;
      flex-direction: column;
      width: 100%;
      flex: 1;
      gap: 10px;
      .brandoverview-rebates {
        flex: 1;
        width: 100%;
        .rebate-item {
          padding: 2px;
          a {
            line-height: 20px;
            font-weight: 600;
          }
        }
      }
      .brandoverview-ads {
        flex: 1;
        width: 100%;
        a {
          line-height: 20px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}

// video {
//   object-fit: cover !important;
// }

.image-gallery-thumbnail-inner {
  display: inline !important;
  video {
    object-fit: cover !important;
  }
}

.rebate-title {
  margin-right: 0.8em;
  align-self: center;
  text-align: left;
  word-break: break-word;
  @media screen and (max-width: 350px) {
    font-size: 15px;
    margin-right: 1em;
  }
}
