.stepper-container-main {
    .rebate-title-main {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    }

    .banner-text {
        font-size: 20px;
        font-weight: 800;
    }

    #root {
        height: 100%;
        width: 100%;
    }

    .footer {
        /* padding-bottom: 1.2em; */
        text-align: center;
        padding: 8px;
    }

    .spacer {
        display: inline;
        padding: 0 10px;
    }

    .contact-info-main {
        box-shadow: 0 0 5px 2px #a1a0a0;
        border-radius: 2px;
        padding: 10px 30px 30px 30px;
        margin-bottom: 20px;
        background-color: #FFF;
    }

    .payment-info-main {
        box-shadow: 0 0 5px 2px #a1a0a0;
        border-radius: 2px;
        padding: 10px 30px 30px 30px;
        margin-bottom: 20px;
        background-color: #FFF;
    }

    .terms-checkbox {
        padding-top: 0 !important;
    }

    .MuiStepper-root {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    @media screen and (min-width: 576px) {
        .MuiStepLabel-iconContainer svg {
            height: 35px !important;
            width: 35px !important;
            font-size: 30px !important;
        }

        .MuiStep-root .MuiStepConnector-root {
            top: 16px;
        }
    }


    .Mui-active .MuiStepConnector-line {
        border: 1px solid rgb(0, 160, 176) !important;
    }

    .Mui-completed .MuiStepConnector-line {
        border: 1px solid rgb(0, 160, 176) !important;
    }

    .MuiStepConnector-line {
        border: 1px solid #CCCCCC !important;
    }

    .Mui-disabled .MuiStepIcon-root {
        color: #F2F2F2 !important;
        border: 2px solid #CCCCCC;
        border-radius: 20px;
    }

    .Mui-disabled .MuiStepLabel-label {
        color: #CCCCCC !important;
    }

    .Mui-disabled .MuiStepIcon-text {
        fill: #CCCCCC !important;
    }

    .MuiStepIcon-text {
        font-weight: 600;
    }

    .MuiGrid-item {
        padding-top: 0 !important;
    }

    .terms-checkbox-first {
        padding-top: 16px !important;
    }

    .form-head-p {
        font-size: 1.1em;
        text-align: left;
        color: black;
    }

    .banner-image {
        object-fit: contain;
        height: 10rem;
        max-width: 50%;
        padding: 0.5rem 1rem;
        transform: rotate(-16deg);
    }

    .MuiFormControl-root {
        margin-bottom: 0 !important;
    }

    .input-subtext {
        color: #777 !important;
        font-size: .7em !important;
    }

    .submit-verify-btn {
        padding: 10px 30px !important;
        font-weight: 600 !important;
        width: 50%;
    }

    .hyperlink-main {
        background-color: transparent !important;
        border: none !important;
        color: rgb(0, 160, 176) !important;
        cursor: pointer !important;
        font-size: inherit !important;
        padding: 0 !important;
        text-decoration: underline !important;
    }

    .header-login-btn {
        color: rgb(0, 160, 176) !important;
        font-size: 16px !important;
        font-weight: bolder !important;
    }

    .header-login-btn:hover {
        background-color: white !important;
    }

    .footer-main {
        background: #00A0B0;
        display: flex;
        align-items: center;
        justify-content: center;
        /*width: 100%;*/
        /*margin-top: 60*/
    }

    .survey-main {
        box-shadow: 0 0 5px 2px #a1a0a0;
        border-radius: 2px;
        padding: 10px 10px 30px 10px;
        margin-bottom: 20px;
        background-color: white !important;
    }

    .survey-main #start-page-image {
        background-color: white !important;
    }

    .survey-main .survey-iframe {
        border: 0 !important;
        margin: 0 auto !important;
        background: transparent !important;
    }

    .file-upload-area {
        border: dashed 3px #00A0B0 !important;
        border-radius: 4px;
        background-color: #F2F2F2;
        padding: 25px 0px;
        text-align: center;
        margin-top: 2.5em;
    }

    .file-upload-area .file-upload-label {
        color: #00A0B0;
        font-weight: bolder;
        margin-top: 0 !important;
    }

    .MuiStepIcon-active, .MuiStepIcon-completed, .Mui-active, .Mui-completed {
        color: rgb(0, 160, 176) !important;
    }

    .file-btn {
        border: 2px solid #00A0B0 !important;
        border-radius: 12px !important;
        color: #777 !important;
        font-weight: bolder !important;

        &.MuiButton-root {
            background-color: transparent !important;
        }
    }

    .MuiButton-root {
        color: #FFFFFF;
        background-color: rgb(0, 160, 176) !important;
    }

    .MuiInput-root:after {
        border-color: rgb(0, 160, 176) !important;
    }

    .MuiFormLabel-root.Mui-disabled {
        color: rgb(0, 160, 176) !important;
    }

    .Mui-focused {
        color: rgb(0, 160, 176) !important;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0, 0, 0, 0.6) !important;
        opacity: 1 !important; /* Firefox */
    }

    .MuiStepLabel-label {
        font-weight: bold !important;
    }

    .payment-footer-text {
        color: black !important;
        font-size: 13px !important;
        font-weight: 400;
    }

    .banner-card {
        color: #333;
        display: flex;
        flex-direction: row;
        font-size: 1.5em;
        font-weight: 600;
        cursor: default;
        box-shadow: 0 0 5px 2px #a1a0a0;
        border-radius: 2px;
        gap: 10;
    }

    .rebate-title-image {
        text-align: center;
    }

    .survey-main iframe {
        height: 2400px;
    }
    //@media screen and (max-width: 576px) {
    //    .send-otp-btn {
    //        font-size: 9px !important;
    //    }
    //}
    @media screen and (max-width: 576px) {
        .MuiStepLabel-label {
            font-size: 12px !important;
        }
        .banner-text {
            font-size: 16px !important;
            font-weight: 800;
            text-align: left;
        }
        .banner-image {
            max-width: 45% !important;
            padding: 0 !important;
            margin-left: -25px;
        }
        .rebate-title-image {
            text-align: left;
        }
        .banner-card {
            padding: 10px;
        }

        .page-label {
            font-size: 28px !important;
        }

        .payment-footer-text {
            font-size: 12px !important;
            letter-spacing: 0 !important;
        }

        .MuiToolbar-root {
            min-height: 60px !important;
        }

        .form-head-p {
            font-size: 15px !important;
        }

        .terms-checkbox-first {
            margin-bottom: 15px !important;
            padding-top: 20px !important;
        }
        .terms-checkbox {
            margin-bottom: 15px !important;
        }
        .terms-checkbox-first .MuiFormControlLabel-label {
            font-size: 15px !important;
        }
        .terms-checkbox .MuiFormControlLabel-label {
            font-size: 15px !important;
        }
        .submit-verify-btn {
            font-size: 12px !important;
            padding: 10px 15px !important;
            width: 100%;
        }
        .survey-main iframe {
            height: 2800px !important;
        }

        .send-otp-btn{
            font-size: 12px;
        }
    }

    *, ::before, ::after {
        box-sizing: inherit;
    }

    .Mui-checked {
        color: rgb(0, 160, 176) !important;
    }

    .Mui-error:after {
        border-bottom-color: #f44336 !important;
    }

    .Mui-error{
        color: #f44336 !important;
    }

    .MuiFormHelperText-root{
        color: #f44336 !important;
    }

    .MuiInput-input{
        color: black !important;
    }

    .otp-btn-section {
        text-align: right;
    }
}
