body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebf0ef;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  margin: 0;
  height: 100%;
}

@media screen and (max-width: 480px) {
  html,
  body {
    width: 100%;
    max-width: 480px !important;
  }
}

#root {
  height: 100%;
  width: 100%;
}

.footer {
  /* padding-bottom: 1.2em; */
  text-align: center;
  padding: 8px;
}

.spacer {
  display: inline;
  padding: 0 10px;
}

.keyword-tags {
  background: #bbb;
  height: 100%;
  display: flex;
  padding: 0.4rem;
  margin: 5px 5px 0px 0px;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  gap: 10;
  flex-direction: row
}
