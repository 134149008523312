.rebates-showcase {
  width: 100%;
  .banner {
    width: 100%;
    height: 300px;
    background: url(../assets/rebate-background.png) no-repeat top center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-size: cover;
    .header {
      position: absolute;
      width: 100%;
      background: transparent;
      padding: 0px;
      margin-top: 10px;
      svg {
        margin-left: 10px;
      }
      button {
        background-color: rgba(138, 207, 198, 0.04) !important;
        span {
          color: white !important;
        }
      }
    }
    &-background {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background: rgba(25, 45, 40, 0.7);
    }
    &-content {
      position: absolute;
      height: 300px;
      display: flex;
      top: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: white;
      font-family: 'Montserrat';
      gap: 20px;
      & > h3 {
        font-size: 35px;
        padding: 0px;
        margin: 0px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
      }
      & > p {
        font-size: 20px;
        padding: 0px;
        margin: 0px;
        font-weight: 300;

        text-align: center;
      }
    }
  }
  .rebates-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px 20px 30px 20px;
    justify-content: center;
    flex: 1;
    gap: 30px;
    background: white;
    .rebates-step {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      flex: 0.2;
      img {
        width: 120px;
        height: 120px;
        object-fit: contain;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          background: #00a0b0;
          border-radius: 50%;
          margin-bottom: 10px;
          text-align: center;
        }
      }
      label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #111111;
        text-align: center;
      }
    }
  }
  .rebates-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1;
    padding-top: 20px;
    &-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 80%;
      h2 {
        color: #111111;
        font-family: 'Montserrat';
        text-align: center;
        width: 100%;
        font-style: normal;
      }
    }

    .rebates-list {
      display: flex;
      padding: 0px;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 90%;
      @media screen and (max-width: 1200px) {
        justify-content: center;
      }
      @media screen and (max-width: 512px) {
        justify-content: center;
      }
    }
  }
}

.tickets {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  width: 95%;
  margin: 10px auto;
  .ticket {
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 100%;
    flex: 1;
    &-title {
      flex: 0.8;
      background: #fcfafa;
      border-radius: 10px;
      padding: 15px 15px;
      position: relative;
      filter: drop-shadow(2px 2px 5px rgba(117, 117, 117, 0.25));
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-amount {
      background: #00a0b0;
      border-radius: 10px;
      padding: 15px 20px;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      max-width: max-content;
      color: white;
      font-weight: bold;

      &::after {
        content: '';
        position: absolute;
        right: -7px;
        top: -8px;
        width: 15px;
        height: 15px;
        background: white;
        border-radius: 50%;
        background: white;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &::before {
        content: '';
        position: absolute;
        right: -7px;
        bottom: -8px;
        width: 15px;
        height: 15px;
        background: white;
        border-radius: 50%;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}

.MuiButton-outlinedPrimary {
  border: 1px solid white !important;
}

@media screen and (max-width: 768px) {
  .rebates-showcase {
    .banner {
      height: 250px;
      .header {
        svg {
          margin-left: 10px;
          // width: 100px;
          &#Layer_1 {
            width: 100px;
          }
        }
        button {
          span {
          }
        }
      }
      &-background {
      }
      &-content {
        height: 250px;
        & > h3 {
          font-size: 25px;
        }
        & > p {
          font-size: 15px;
        }
      }
    }
    .rebates-steps {
      gap: 50px;
      .rebates-step {
        img {
        }
        div {
          p {
          }
        }
        label {
        }
      }
    }
    .rebates-container {
      h2 {
      }
      .rebates-list {
        @media screen and (max-width: 1200px) {
          justify-content: flex-start;
        }
        @media screen and (max-width: 512px) {
          justify-content: center;
        }
      }
    }
  }

  .tickets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    width: 90%;
    margin: 10px auto;
    .ticket {
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-width: 100%;
      flex: 1;
      &-title {
        flex: 0.8;
        background: #fcfafa;
        border-radius: 10px;
        padding: 15px 15px;
        position: relative;
        filter: drop-shadow(2px 2px 5px rgba(117, 117, 117, 0.25));
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      }
      &-amount {
        background: #00a0b0;
        border-radius: 10px;
        padding: 15px 20px;
        transform: rotate(180deg);
        writing-mode: vertical-rl;
        max-width: max-content;
        color: white;
        font-weight: bold;
        text-align: center;
        &::after {
          content: '';
          position: absolute;
          right: -7px;
          top: -8px;
          width: 15px;
          height: 15px;
          background: white;
          border-radius: 50%;
          background: white;
          &:hover {
            background: rgba(0, 0, 0, 0.04);
          }
        }
        &::before {
          content: '';
          position: absolute;
          right: -7px;
          bottom: -8px;
          width: 15px;
          height: 15px;
          background: white;
          border-radius: 50%;
          &:hover {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 512px) {
  .rebates-showcase {
    .rebates-steps {
      flex-direction: column;
      .rebates-step {
        width: 100%;
      }
    }
  }
}

.advisor-main {
  .MuiCard-root {
    height: 510px !important;
  }
  .MuiCardContent-root {
    padding: unset !important;
  }
  img {
    height: 220px !important;
    //object-fit: cover !important;
    width: 100% !important;
  }
  .bio-main {
    font-size: 12px;
    margin-top: 5px;
  }
  .title-main {
    font-size: 11px;
  }
  .MuiPaper-root {
    ul {
      padding-top: 0 !important;
      li {
        padding: 0 !important;
      }
    }
  }
}