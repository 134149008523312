.pos-main {
    .MuiTableContainer-root {
        background: none !important;
        box-shadow: unset !important;
    }
    .MuiFormLabel-root.Mui-focused {
        color: #00a0b0 !important;
    }
    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: #00a0b0 !important;
        }
    }
    .product-main {
        display: flex;
        align-items: center;
    }
    .product-image {
        height: 70px !important;
        width: 70px;
    }

    .check-coupon {
        display: flex;
        button {
            margin-left: 10px !important;
            background: rgb(0, 160, 176) !important;
        }
    }

    thead {
        th {
            font-weight: 900 !important;
        }
        tr {
            border-bottom: 2px solid rgb(0, 160, 176);
        }
    }

    tbody {
        tr {
            td {
                font-weight: 500 !important;
            }
        }
    }

    tr {
        border-bottom: 1px solid rgb(0, 160, 176);
    }

    th, td {
        border-bottom: none !important;
        color: #00a0b0 !important;
    }
    .total-main {
        width: 100%;
        padding-right: 16px;
        text-align: right;
        color: #00a0b0 !important;
    }
    .bottom-main {
        width: 100%;
        .checkout-main {
            button {
                background: rgb(0, 160, 176)
            }
            text-align: right;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .notes {
            font-size: 12px;
            color: #00a0b0;
            font-weight: 500;
        }
        .notes-tax {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 900;
        }
    }
    #filled-quantity {
        color: #00a0b0 !important;
    }
    .close-icon {
        cursor: pointer;
    }

}